
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import RegisterFormCard from '@/components/RegisterFormCard.vue';

    @Component<ViewRegister>({
        components: {
            RegisterFormCard,
        },
    })
    export default class ViewRegister extends Vue {
        public onRegistered() {
            this.$router.push({name: 'home'});
        }

        public goToLogin() {
            this.$router.push({name: 'login'});
        }
    }
