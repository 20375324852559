
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {Prop} from 'vue-property-decorator';

    import {formHelper} from '@/helpers';
    import {IAccount, ISpecialty, IRegistration} from '@/types';
    import {AccountModule, RegistrationModule, SnackbarModule} from '@/store/modules';

    import PhoneInput from '@/components/forms/PhoneInput.vue';
    import PlaceInput from '@/components/forms/PlaceInput.vue';

    const accountNamespace = namespace('account');
    const veterinarianNamespace = namespace('veterinarian');
    const registrationNamespace = namespace('registration');

    @Component<RegisterFormCard>({
        components: {
            PlaceInput,
            PhoneInput,
        },
    })
    export default class RegisterFormCard extends Vue {
        public type: string|null = null;

        public accountFormValid = false;
        public submittingAccount = false;
        public email: string = '';
        public emailConfirmation: string = '';
        public password: string = '';
        public acceptTerms = false;
        public showpassword: boolean = false;

        public detailsFormValid = false;
        public submittingDetails = false;
        public firstName: string = '';
        public lastName: string = '';
        public cellPhone: string = '';
        public specialty: ISpecialty|null = null;

        public validateFormValid = false;
        public resendingCode = false;
        public verifyingCode = false;
        public code: string|null = null;

        public emailExistsDialog = false;

        public resendingEmail: boolean = false;
        public verifyingEmail: boolean = false;
        public invalidVerificationToken: boolean = false;
        public invalidVerificationTokenReason: string|null = null;

        public emailRules = formHelper.getEmailsRules();
        public firstNameRules = formHelper.getFirstNameRules();
        public lastNameRules = formHelper.getLastNameRules();
        public specialtyRules = formHelper.getSpecialtyRules();
        public termsRules = formHelper.getTermsRules();

        @Prop({
            type: String,
            required: false,
        })
        public initialEmail!: string;

        @accountNamespace.Action('clearStatus')
        public clearStatus!: () => void;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount|null;

        @veterinarianNamespace.Getter('specialtiesList')
        public specialtiesList!: ISpecialty[];

        @registrationNamespace.Getter('registration')
        public registration!: IRegistration;

        get step() {
            if (this.invalidVerificationToken) {
                return 'invalid_verification_token';
            }

            if (this.loggedAccount) {
                if (this.loggedAccount.status === 'pending_details') {
                    this.type = this.loggedAccount.type;
                }

                return this.loggedAccount.status;
            }

            return this.type ? 'account' : 'type';
        }

        public passwordRules = [
            (v: string) => (!v || /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*().,;:\/?^"'\-+])[A-Za-z\d!@#$%^&*().,;:\/?^"'\-+]{12,}$/.test(v)) || `Le mot de passe doit contenir au moins 12 caractères, au moins un chiffre et un caractère spécial !@#$%^&*().,;:?^"+' minimum.`
        ];

        public emailConfirmationRules = [
            (v: string) => !!v || 'Veuillez confirmer votre e-mail',
            this.validateEmailConfirmation,
        ];

        public validateEmailConfirmation(v: string) {
            return v === this.email || 'Les adresses e-mail doivent être identiques';
        }

        public checkEmailConfirmation() {
            if (this.emailConfirmation) {
                (this.$refs.emailConfirmation as any).validate();
            }
        }

        public clearInvalidVerificationToken() {
            this.invalidVerificationToken = false;
            this.invalidVerificationTokenReason = null;
        }

        public submitAccount() {
            this.submittingAccount = true;
            getModule(AccountModule, this.$store)
                .register({
                    email: this.email,
                    password_raw: this.password,
                    accept_terms: this.acceptTerms,
                    type: this.type as string,
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 409) {
                            this.emailExistsDialog = true;
                        }
                    }
                })
                .finally(() => {
                    this.submittingAccount = false;
                    this.clearStatus();
                })
            ;
        }

        public submitDetails() {
            const accountModule = getModule(AccountModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.submittingDetails = true;

            if (this.type === 'veterinarian') {
                accountModule
                    .createVeterinarian({
                        account_id: (this.loggedAccount as IAccount).id,
                        first_name: this.firstName,
                        last_name: this.lastName,
                        cell_phone: this.cellPhone,
                        specialty_id: (this.specialty as ISpecialty).id,
                    })
                    .then(() => {
                        snackbarModule.displaySuccess('Votre compte a bien été créé !');
                        this.$router.push('/');
                    })
                    .catch((error) => snackbarModule.displayError())
                    .finally(() => {
                        this.submittingDetails = false;
                        this.clearStatus();
                    })
                ;
            } else {
                accountModule
                    .createParaveterinarian({
                        account_id: (this.loggedAccount as IAccount).id,
                        first_name: this.firstName,
                        last_name: this.lastName,
                        cell_phone: this.cellPhone,
                    })
                    .then(() => {
                        snackbarModule.displaySuccess('Votre compte a bien été créé !');
                        this.$router.push('/');
                    })
                    .catch((error) => snackbarModule.displayError())
                    .finally(() => {
                        this.submittingDetails = false;
                        this.clearStatus();
                    })
                ;
            }
        }

        public resendEmail() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            if (!this.loggedAccount) {
                snackbarModule.displayError('Vous devez être connecté');
                return;
            }

            this.resendingEmail = true;

            this.$api.account
                .resendVerificationEmail(this.loggedAccount.id)
                .then(() => {
                    snackbarModule.displaySuccess('L\'email de validation a bien été renvoyé !');
                })
                .catch(() => this.invalidVerificationToken = true)
                .finally(() => this.resendingEmail = false)
            ;
        }

        public created() {
            const token = this.$route.query.email_verification_token;
            const invitationId = this.$route.query.invitation;

            if (token) {
                const snackbarModule = getModule(SnackbarModule, this.$store);
                const accountModule = getModule(AccountModule, this.$store);

                this.verifyingEmail = true;

                accountModule
                    .verifyEmail(token as string)
                    .then(() => {
                        snackbarModule.displaySuccess('Votre adresse email a bien été validée !');
                    })
                    .catch((error) => {
                        if (error.response.data.error === 'account_already_validated') {
                            snackbarModule.displayError('Vous avez déjà validé votre adresse email');
                        } else if (error.response.data.error === 'account_deleted') {
                            snackbarModule.displayError('Ce compte a été supprimé');
                        }

                        this.invalidVerificationTokenReason = error.response.data.error;
                        this.invalidVerificationToken = true;
                    })
                    .finally(() => this.verifyingEmail = false)
                ;
            }

            if (typeof invitationId === 'string') {
                getModule(RegistrationModule, this.$store)
                    .fetchInvitation(invitationId)
                    .then((invitation) => {
                        this.email = invitation.email;
                        this.emailConfirmation = invitation.email;
                    })
                ;
            }
        }

        public mounted() {
            if (this.registration) {
                this.email = this.registration.email;
                this.emailConfirmation = this.registration.emailConfirmation;
                this.firstName = this.registration.firstName;
                this.lastName = this.registration.lastName;
                this.cellPhone = this.registration.phone;
            }
        }
    }
